<template>
  <section>
    <section>
      <pm-Breadcrumb :home="home" :model="items_bread" class="hidden-mobile" />
      <div class="hide-presenca-mob">
        <p style="text-align: center; font-size: 15px;" ><b v-for="b in items_bread">{{ b.label }} &nbsp</b></p>
      </div>
      <br>
      <div class="form-elements">
        <div class="row">
          <div class="col-md-9 col-9">
            <div>
              <h2 class="titulo-links-escolha exibe-mobile-msg2">
                Conteúdo Aplicado
                <va-popover
                  placement="right"
                  color="primary"
                  title="Informação do módulo atual"
                  message="Aqui o professor poderá lançar o conteúdo aplicado em cada aula!"
                >
                  <i class="pi pi-question-circle" style="font-size: 1.5rem"></i>
                </va-popover>
              </h2>
              <h2 class="titulo-links-escolha exibe-mobile-msg"  style="margin-top: 7px;">
                Conteúdo Aplicado
              </h2>
            </div>
          </div>
          <div class="col-md-3 col-3" >
            <pm-Button disabled="true" v-if="tipo == 2" label="Adicionar atividade" icon="pi pi-external-link" @click="dialogAction" style="margin-top: 5px;" />
            <pm-Button type="button" style="float: right;margin-top: 5px;"
              @click="trocarTurma()" label="Trocar de Turma"
              class="p-button-danger trocar-turma" icon="pi pi-angle-left" iconPos="left"
            ></pm-Button>
          </div>
        </div>
      </div>

      <p class="exibe-mobile-msg"> Aqui o professor poderá lançar o conteúdo aplicado em cada aula!</p>
      <div class="row">
        <div class="col-md-4" >
          <selecionarDisciplinasProfessor  @Config="Config" :disciplina="disciplina"  />
          <pm-Button :disabled="!situacaoAno" v-if="exibirBotao == true && acesso.cadastrar && tipo == 1" label="Adicionar atividade" icon="pi pi-external-link" @click="dialogAction" />
        </div>

        <div class="col-md-8" v-if="timelineInfo.length && acesso.visualizar">
          <div class="card" style="padding-bottom: 15px;padding-top: 15px;">
            <pm-Timeline :value="timelineInfo" align="alternate" class="customized-timeline">
              <template #marker="slotProps">
                <span class="custom-marker shadow-2" :style="{backgroundColor: slotProps.item.color}">
                  <i :class="slotProps.item.icon"></i>
                </span>
              </template>
              <template #content="slotProps">
                <pm-Card>
                  <template #title>
                    <h5>
                      {{slotProps.item.anoShow}}
                    </h5>
                  </template>
                  <template #subtitle>
                    <br>
                    <h6 style="font-weight:normal;color: #4e3e3e;">
                      {{slotProps.item.status}}:
                    </h6>
                  </template>
                  <template #content>
                    <p  v-for="(item, index) in slotProps.item.textoExibir" style="text-align: justify;float:right;">
                      {{item}}
                      <br>
                    </p>
                    <div class="col-md-12" style="padding-top:35px;">
                      <br>
                      <pm-Button :disabled="!situacaoAno" title="Editar conteúdo" icon="pi pi-pencil" class="p-button-rounded p-button-primary" v-if="acesso.editar"
                        @click="editarAtividadePedagociaOption(slotProps)"/>
                              &nbsp;
                      <pm-Button  :disabled="!situacaoAno" title="Apagar conteúdo" icon="pi pi-times" class="p-button-rounded p-button-danger" v-if="acesso.deletar"
                        @click="deletarAtividadePedago(slotProps)" />
                    </div>
                  </template>
                </pm-Card>
              </template>
            </pm-Timeline>
          </div>
        </div>
      </div>
      <pm-Dialog header="Atividade lecionada" v-model:visible="displayMaximizable" :style="{width: '100vw'}" :maximizable="true" :modal="true">
        <div class="p-fluid formgrid grid" >
          <div class="field col-12 md:col-4">
            <label for="nascimento">Informe a data:</label>
            <pm-InputText @change="validateForm" v-model="infoParaEnviar.data" type="date" :class="{'p-invalid': validationErrors.data  && submitted}"/>
            <small v-show="validationErrors.data && submitted" class="p-error">A data é obrigatória!</small>

          </div>
          <div class="field col-12 md:col-12">
            <label for="ativiade">Informe a atividade lecionada:</label>
            <pm-Textarea  rows="12" @change="validateForm" v-model="infoParaEnviar.texto" :class="{'p-invalid': validationErrors.texto  && submitted}"/>
            <small   v-show="validationErrors.texto && submitted" class="p-error">A atividade lecionada é obrigatória!</small>
          </div>
        </div>
        <template #footer>
          <pm-Button label="Fechar" icon="pi pi-times" @click="dialogAction" class="p-button-text"/>
          <pm-Button v-if="botaoEditar == false && acesso.cadastrar"  label="Salvar" icon="pi pi-check" @click="salvarAtividadePedagocia" autofocus />
          <pm-Button v-if="botaoEditar == true && acesso.editar"  label="Editar" icon="pi pi-check" @click="editarAtividade" autofocus />
        </template>
      </pm-Dialog>

      <!-- tipo 2 -->
      <pm-Dialog header="Atividade lecionada" v-model:visible="displayMaximizable2" :style="{width: '100vw'}" :maximizable="true" :modal="true">
        <div class="p-fluid formgrid grid" >
          <div class="field col-12 md:col-12">

            <div class="form-check" v-for="n in disciplina" :key="n" style="display:inline-block">
              <input class="form-check-input" v-model="disciplinas_tipo2" type="checkbox" :value="n.disciplina_id" >
              <label class="form-check-label" style="margin-top: 0px !important;margin-right: 5px;">{{ n.disciplina }}</label>
            </div>

          </div>
          <div class="field col-12 md:col-4">
            <label for="nascimento">Informe a data:</label>
            <pm-InputText @change="validateForm" v-model="infoParaEnviar.data" type="date" :class="{'p-invalid': validationErrors.data  && submitted}"/>
            <small v-show="validationErrors.data && submitted" class="p-error">A data é obrigatória!</small>
          </div>
          <div class="field col-12 md:col-12">
            <label for="ativiade">Informe a atividade lecionada:</label>
            <pm-Textarea  rows="12" @change="validateForm" v-model="infoParaEnviar.texto"
              :class="{'p-invalid': validationErrors.texto  && submitted}"
              v-if="disciplinas_tipo2.length > 0"
            />

            <pm-Textarea  rows="12" @change="validateForm" disabled v-else/>

            <small   v-show="validationErrors.texto && submitted" class="p-error">A atividade lecionada é obrigatória!</small>
          </div>
        </div>
        <template #footer>
          <pm-Button label="Fechar" icon="pi pi-times" @click="dialogAction" class="p-button-text"/>
          <pm-Button v-if="botaoEditar == false && acesso.cadastrar && disciplinas_tipo2.length > 0"
            label="Salvar" icon="pi pi-check" @click="salvarAtividadePedagocia" autofocus />
          <pm-Button v-else label="Salvar" icon="pi pi-check" disabled />

          <pm-Button v-if="botaoEditar == true && acesso.editar"  label="Editar" icon="pi pi-check" @click="editarAtividade" autofocus />
        </template>
      </pm-Dialog>

    </section>
  </section>
</template>


<script>



import selecionarDisciplinasProfessor from '@/components/selecionarDisciplinasProfessor.vue'

import { defineComponent } from 'vue'
import { Turma } from "@/class/turma";
import { Verificar } from "@/class/verificar.js";
import { PlanejamentoPedagogico } from "@/class/planejamentopedagogico";
import { Servidores } from "@/class/servidores";


export default defineComponent({
  props: {
    id:{
      required: true
    },
    turma_id:{
      required: true
    },
    professor_id:{
      required: true
    },
    turma_disciplina_id:{
      required: true
    },
    segmento:{
      required: true
    },
    dataSelecionada:{
      required:true,
    },
    escola_id:{
      required:true,
    },
    serie_id:{
      required:true,
    },
    turno:{
      required:true,
    }
  },
  components: {
    selecionarDisciplinasProfessor,
  },
  data () {
    return {
      tipo: 1,
      theAno: '',
      displayMaximizable:false,
      displayMaximizable2: false,
      exibirBotao:false,
      botaoEditar:false,
      home: {icon: 'pi pi-home', to: '/ambiente-professor/turmas-professor'},
      items_bread: [
        {label: ''},
      ],
      st_id: null,
      st_turma_id: null,
      st_professor_id: null,
      st_turma_disciplina_id:null,
      st_segmento:null,
      st_dataSelecionada:null,
      st_escola_id:null,
      st_serie_id:null,
      st_turno:null,
      turma_dados:[],
      infoParaEnviar: {
            segmento_id : '',
            turma_id : '',
            serie_id : '',
            ano : '',
            infoParaEnviar : '',
            data:'',
            texto:'',
      },
      disciplina: [],
      disciplinas_tipo2:[],
      timelineInfo:[],
      validationErrors: {},
      submitted:false,

      acesso:{
        visualizar:1,
        cadastrar:1,
        editar:1,
        deletar:1,
      },
      situacaoAno: 0
    }
  },
  methods:{
    async init(){
      try{
        this.theAno = sessionStorage.getItem("anoSelecionado");
      }catch(e){

      }
    },
    dialogAction() {
      this.infoParaEnviar.data = '';
      this.infoParaEnviar.texto = '';
      if(this.tipo == 1){
        this.displayMaximizable = !this.displayMaximizable;
        delete this.validationErrors['data'];
        delete this.validationErrors['texto'];
        this.botaoEditar = false;
        this.submitted = false;
      }else{
        this.disciplinas_tipo2 = [];
        this.displayMaximizable2 = !this.displayMaximizable2;
        delete this.validationErrors['data'];
        delete this.validationErrors['texto'];
        this.botaoEditar = false;
        this.submitted = false;
      }
    },
    async carregarTimeLine() {
        this.timelineInfo = [];
        const nv = {
            turma_id: this.st_turma_id,
            professor_id: this.st_professor_id,
            disciplina_id: this.infoParaEnviar.disciplina_id,
            ano: this.theAno
        }
        const data = await PlanejamentoPedagogico.obtemPlanejamentoProf(nv);
        for (const el of data.data) {

            let mes = '', ano = '', dia = '';
            if (el.mes < 10)
                {mes = "0" + String(el.mes);}
            else
                {mes = String(el.mes);}


            if (el.dia < 10)
                {dia = "0" + String(el.dia);}
            else
                {dia = String(el.dia);}
            ano = String(el.ano);
            //Criar parágrafo para cada linha em branco
            let paragraphs = '';
            if (el.texto == null) {
              paragraphs = '';
            }
            else {
              paragraphs = el.texto.split(/[\n\r]+/g).filter((p) => p.trim());
            }

            const nv = {
                id: el.id,
                status : "Atividade realizada",
                icon : 'pi pi-check',
                color : '#607D8B',
                textoExibir: paragraphs,
                textoEditar: el.texto,
                anoOrder:  ano + '-' +  mes + '-' +  dia,
                anoShow : dia + '/' + mes + '/' + ano
            }
            this.timelineInfo.push(nv);
        }
        //ordernar as tarefas pela data
        this.timelineInfo.sort((a,b) => (a.anoOrder > b.anoOrder) ? 1 : ((b.anoOrder > a.anoOrder) ? -1 : 0));

        //inverter a ordem, da mais recente para a mais velha. De cima para baixo.
        this.timelineInfo.reverse();
    },
    validateForm() {
        if (this.infoParaEnviar.data == null || this.infoParaEnviar.data == '')
            {this.validationErrors['data'] = true;}
        else
            {delete this.validationErrors['data'];}

        if (this.infoParaEnviar.texto == null || this.infoParaEnviar.texto == '')
            {this.validationErrors['texto'] = true;}
        else
            {delete this.validationErrors['texto'];}
        return !Object.keys(this.validationErrors).length;
    },
    async salvarAtividadePedagocia() {
        this.submitted = true;
        if (this.validateForm()) {
            try {
                const pf = await Servidores.buscarServidorIdLogin(this.st_professor_id);
                const s = this.infoParaEnviar.data.split("-");

                if(this.tipo == 1){
                  const nv = {
                      turma_id: this.st_turma_id,
                      professor_id:  pf.data.login_id,
                      disciplina_id: this.infoParaEnviar.disciplina_id,
                      texto: this.infoParaEnviar.texto,
                      dia:  parseInt(s[2]),
                      mes:  parseInt(s[1]),
                  }
                  const data = await PlanejamentoPedagogico.addPlanejamentoProf(nv);
                }else{
                  for (let i = 0; i < this.disciplinas_tipo2.length; i++) {
                    const disc = this.disciplinas_tipo2[i];

                    const nv = {
                      turma_id: this.st_turma_id,
                      professor_id:  pf.data.login_id,
                      disciplina_id: disc,
                      texto: this.infoParaEnviar.texto,
                      dia:  parseInt(s[2]),
                      mes:  parseInt(s[1]),
                    }

                    const data = await PlanejamentoPedagogico.addPlanejamentoProf(nv);
                  }
                }

                this.$vaToast.init({
                message: "Planejamento salvo com sucesso!",
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'success'
                });
                this.dialogAction();
                this.carregarTimeLine();
            }
            catch(e) {
              console.log(e)
                if(e.response.data.validacao){
                    let a = [];
                    a = e.response.data.validacao;

                    Object.entries(a).forEach(([key, value]) => {
                        setTimeout(() => {
                        this.$vaToast.init({
                            message: value[0],
                            iconClass: 'fa-star-o',
                            position: 'top-right',
                            duration: 3500,
                            fullWidth: false,
                            color: 'danger'
                        });
                        }, 500);
                    });

                    }else{
                    let a = [];
                    a = e.response.data.erro;

                    setTimeout(() => {
                    this.$vaToast.init({
                        message: a,
                        iconClass: 'fa-star-o',
                        position: 'top-right',
                        duration: 3500,
                        fullWidth: false,
                        color: 'danger'
                    });
                    }, 500);
                }
            }
        }
    },
    async editarAtividadePedagociaOption(atividade) {
      this.infoParaEnviar.id = atividade.item.id;
      this.infoParaEnviar.data = atividade.item.anoOrder;
      this.infoParaEnviar.texto = atividade.item.textoEditar;

      delete this.validationErrors['data'];
      delete this.validationErrors['texto'];
      this.displayMaximizable = !this.displayMaximizable;
      this.botaoEditar = true;
      this.submitted = false;
    },
    async editarAtividade(atividade) {
      this.submitted = true;
      if (this.validateForm()) {
        try {
          const s = this.infoParaEnviar.data.split("-");
          const nv = {
              id:  this.infoParaEnviar.id,
              texto: this.infoParaEnviar.texto,
              dia:  parseInt(s[2]),
              mes:  parseInt(s[1]),
          }
          const data = await PlanejamentoPedagogico.editarPlanejamentoProf(nv);
          this.$vaToast.init({
          message: "Planejamento editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'success'
          });
          this.dialogAction();
          this.carregarTimeLine();
        }
        catch(e) {
          if(e.response.data.validacao){
            let a = [];
            a = e.response.data.validacao;

            Object.entries(a).forEach(([key, value]) => {
                setTimeout(() => {
                this.$vaToast.init({
                    message: value,
                    iconClass: 'fa-star-o',
                    position: 'top-right',
                    duration: 3500,
                    fullWidth: false,
                    color: 'danger'
                });
                }, 500);
            });

          }
          else{
            let a = [];
            a = e.response.data.erro;
            setTimeout(() => {
              this.$vaToast.init({
                  message: a,
                  iconClass: 'fa-star-o',
                  position: 'top-right',
                  duration: 3500,
                  fullWidth: false,
                  color: 'danger'
              });
            }, 500);
          }
        }
      }
    },
    async deletarAtividadePedago(atividade) {
      if (confirm("Você realmente deseja excluir o conteúdo selecionado?")) {
        try {
          const data = await PlanejamentoPedagogico.deletarPlanejamentoProf(atividade.item.id);
          this.$vaToast.init({
            message: "Apagado com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3500,
            fullWidth: false,
            color: 'success'
          });
          this.carregarTimeLine();
        }
        catch(e) {
          if(e.response.data.validacao){
            let a = [];
            a = e.response.data.validacao;

            Object.entries(a).forEach(([key, value]) => {
                setTimeout(() => {
                this.$vaToast.init({
                    message: value,
                    iconClass: 'fa-star-o',
                    position: 'top-right',
                    duration: 3500,
                    fullWidth: false,
                    color: 'danger'
                });
                }, 500);
            });

          }
          else{
            let a = [];
            a = e.response.data.erro;
            setTimeout(() => {
              this.$vaToast.init({
                  message: a,
                  iconClass: 'fa-star-o',
                  position: 'top-right',
                  duration: 3500,
                  fullWidth: false,
                  color: 'danger'
              });
            }, 500);
          }
        }
      }
    },
    async disciplinasPresenca() {
      const novo = {
        turma_id: this.st_turma_id,
        professor_id: this.st_professor_id
      };
      const data = await Turma.obtemTurmaProfessorV2(novo);
      this.disciplina = data.data;
    },
    async Config(disciplina) {
      this.infoParaEnviar.segmento_id = this.st_segmento;
      this.infoParaEnviar.turma_id = this.st_turma_id;
      this.infoParaEnviar.serie_id = this.st_serie_id;
      this.infoParaEnviar.ano = this.theAno;
      this.infoParaEnviar= disciplina;
      this.exibirBotao = true;
      this.carregarTimeLine();
    },
    trocarTurma(){
      this.$router.push({ name: "turmas-Professor" });
    },
  },
  async beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));

    const acesso = await Verificar.AcessoLocal(15);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
    if(this.id == null ||  this.turma_id == null){
      this.st_id = sessionStorage.getItem("Professor_id");
      this.st_turma_id = sessionStorage.getItem("Professor_turma_id");
      this.st_professor_id = sessionStorage.getItem("Professor_professor_id");
      this.st_turma_disciplina_id = sessionStorage.getItem("Professor_turma_disciplina_id");
      this.st_segmento = sessionStorage.getItem("Professor_segmento");
      this.st_escola_id = sessionStorage.getItem("Professor_escola_id");
      this.st_serie_id = sessionStorage.getItem("Professor_serie_id");
      this.st_turno = sessionStorage.getItem("Professor_turno");
      this.st_dataSelecionada = sessionStorage.getItem("Professor_dataSelecionada");

    }else{
      this.st_id = this.id;
      this.st_turma_id= this.turma_id;
      this.st_professor_id = this.professor_id;
      this.st_turma_disciplina_id =this.turma_disciplina_id;
      this.st_segmento =this.segmento;
      this.st_escola_id =this.escola_id;
      this.st_serie_id =this.serie_id;
      this.st_turno =this.turno;
      this.st_dataSelecionada = this.dataSelecionada;

    }
    await this.init();
    await  this.disciplinasPresenca();
    const turma = {
        turma_id: this.st_turma_id,
        etapa_id: this.st_segmento,
        periodo_id: this.st_serie_id,
        escola_id: this.st_escola_id,
    }
    const resp_turma = await Turma.aturma(turma);
    const  tt = [
        {
          label: resp_turma.data[0].escola
        },
        {
          label: resp_turma.data[0].etapa
        },
        {
          label: resp_turma.data[0].periodo
        },
        {
          label: resp_turma.data[0].turma
        },
        {
          label: resp_turma.data[0].turno
        },
    ];
    this.items_bread = tt;
    this.turma_dados = resp_turma.data;
  }
})
</script>


<style lang="scss" scoped>

  .botaoStyle {
    color: #171819 !important;
    border:aliceblue!important;
    background-color: #ffff !important;
  }


  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .cabecario-turma{
    background-color:#4da4e9;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }
  @media(max-width:500px){
    .titulo-links-escolha {
      font-size: 24px;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #f8f9fa !important;
    color: #495057 !important;
    border-right: 1px solid #e9ecef !important;
    border-bottom: 2px solid #e9ecef !important;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .th_per2{
    background-color: #3f5287;
    color: #fff;
    border-right: 1px solid #fff;
    border-bottom: 2px solid #fff;
    text-align: center;
    font-weight: 500;
  }
  .th_pn_aluno{
    width:15%;
  }
  @media(max-width:720px){
    .th_pn_aluno{
      width:90%;
    }
  }

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 22px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

  .semana{
    background-color: rgb(239 239 239);
    text-align: center;
  }
  .font-semana{
    font-size: 10px;
  }
  .dias{
    text-align: center;
  }
  .dia-marcado{
    background-color: #4182c5 !important;
    color: #fff;
  }


  .custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
  }

    ::v-deep(.p-timeline-event-content)
    ::v-deep(.p-timeline-event-opposite) {
        line-height: 1;
    }

    @media screen and (max-width: 960px) {
        ::v-deep(.customized-timeline) {
                .p-timeline-event:nth-child(even) {
                    flex-direction: row !important;

                    .p-timeline-event-content {
                        text-align: left !important;
                    }
                }

                .p-timeline-event-opposite {
                    flex: 0;
                }

                .p-card {
                    margin-top: 1rem;
                }
            }
    }
</style>
